import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../svg/logo.svg"
import { Link } from "gatsby"
import styles from "../styles/404.module.scss"

const NotFoundPage = () => (
  <Layout strip>
    <SEO title="404: Page Not found" />
    <div className={styles.not}>
      <Logo className="logo" />
      <h1>404: Not Found</h1>
      <p>
        The requested URL was not found on this server.
      </p>
      <p>
        <Link to="/">Return to home page</Link> <br />
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
